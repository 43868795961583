export function moveCursorToInputEnd(el) {
    if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {
        el.focus();
        var range = el.createTextRange();
        range.collapse(false);
        range.select();
    }
}

export function handleErrors(errors) {
  clearErrors();

  if (Object.keys(errors).length > 0) {
    Object.entries(errors).forEach(([attribute, error]) => showError(attribute, error));
  }
}

export function replaceHTML5ValidationUI(form) {
  // Suppress the default bubbles
  form.addEventListener( "invalid", function( event ) {
    event.preventDefault();
  }, true );

  // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
  // form submissions by default
  form.addEventListener( "submit", function( event ) {
    if ( !this.checkValidity() ) {
      event.preventDefault();
    }
  });

  const submitButton = form.querySelector( "button:not([type=button]), input[type=submit]" );
  submitButton.addEventListener( "click", function( event ) {
    const invalidFields = form.querySelectorAll( ":invalid" );

    clearErrors();
    invalidFields.forEach((field) => {
      showError(field.name, field.validationMessage);
    });

    if ( invalidFields.length > 0 ) {
      invalidFields[0].focus();
    }
  });
}

function showError(attribute, error) {
  const element = $(`[name='${attribute}'`);
  const errorId = `${attribute}-error`;
  const label = $(`label[for='${attribute}']`);
  const errorMessage = `<span id="${errorId}" class="form-error is-visible">${error}</span>`;

  if ($(`#${errorId}`).length == 0) {
    element.after(errorMessage);
    label.addClass("is-invalid-label");
  }
}

function clearErrors() {
  $(".is-invalid-label").removeClass("is-invalid-label");
  $(".form-error").remove();
}
