var React = require('react');

function EmbedIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"className":"primary-fill","d":"M.1 11.42v22.13a7.1 7.1 0 0 0 7.1 7.1h30.09a7.1 7.1 0 0 0 7.1-7.1V11.42a7.1 7.1 0 0 0-7.1-7.1H7.2a7.1 7.1 0 0 0-7.1 7.1zM10.76 23.8l6.83 6.83a1.71 1.71 0 0 1 .16 2.3 1.64 1.64 0 0 1-2.41.1L6.12 23.8a1.85 1.85 0 0 1 0-2.62l9.23-9.23a1.64 1.64 0 0 1 2.43.13 1.71 1.71 0 0 1-.18 2.26l-6.84 6.84a1.85 1.85 0 0 0 0 2.62zm27.61 0L29.14 33a1.64 1.64 0 0 1-2.41-.1 1.71 1.71 0 0 1 .16-2.3l6.83-6.83a1.85 1.85 0 0 0 0-2.62l-6.84-6.84a1.71 1.71 0 0 1-.18-2.26 1.64 1.64 0 0 1 2.43-.13l9.23 9.23a1.85 1.85 0 0 1 .01 2.65z"}));
}

EmbedIcon.defaultProps = {"viewBox":"0 0 44.74 44.74","className":"icon social embed"};

module.exports = EmbedIcon;

EmbedIcon.default = EmbedIcon;
