import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Inflection from "inflection";
import { parameterize } from 'Utils/StringUtils';

export class TrialDownloadsRemaining extends React.Component {
  constructor(props) {
    super(props);

    const { site_product_type: siteProductType } = this.props;

    this.state = {
      count: null,
      siteProductType,
    };

    window.trialDownloadsRemaining = this;
  }

  componentDidMount() {
    this.fetchCount();
  }

  fetchCount() {
    const { siteProductType } = this.state;

    $.getJSON(Routes.igniter_trial_downloads_remaining_path(parameterize(siteProductType)))
      .done((response) => {
        const { trial_downloads_remaining: count } = response;

        this.setState({ count });
      });
  }

  render() {
    const { site_product_type: siteProductType } = this.props;
    const { count } = this.state;

    return (
      count >= 0
        ? (
          <p className="trial-downloads-remaining-message">
            <span className="count">{count}</span> {siteProductType} {Inflection.inflect('download', count)} remaining in trial
          </p>
        ) : null
    );
  }
}

TrialDownloadsRemaining.propTypes = {
  site_product_type: PropTypes.string.isRequired,
}
