import gtag from 'gtag';
import CrashdownData from 'CrashdownData';
import checkoutsCreateHandler from './google/checkouts_create';

function productBrowseFilterEvent(action) {
  gtag(
    'event',
    action,
    {
      event_category: 'UI Interaction',
      event_label: 'ProductBrowse#FilterSidebar',
    },
  );
}

const handlers = {
  'crashdown-client.pages.view': ({ payload }) => {
    const subdomainConfig = CrashdownData.application.analytics;
    Object.keys(subdomainConfig).forEach((key) => {
      const analyticsPayload = { send_to: subdomainConfig[key] };
      if (key.startsWith('GA4')) {
        analyticsPayload.membership_plan_name = payload.plan_name;
      } else {
        analyticsPayload.dimension1 = payload.plan_name;
      }

      gtag('event', 'page_view', analyticsPayload);
    });
  },
  'crashdown-client.product-browse.filter-interaction': () => {
    productBrowseFilterEvent('Filter Interaction');
  },
  'crashdown-client.product-browse.toggle-filter-sidebar': ({ payload }) => {
    productBrowseFilterEvent(payload.opened ? 'Toggle Open' : 'Toggle Close');
  },

  'crashdown.checkouts.create': checkoutsCreateHandler,
};

function getHandler(event) {
  return handlers[event] || (() => {});
}

export default function ({ event, payload }) {
  getHandler(event)({ event, payload });
}
