import ActiveCampaign from '../handlers/ActiveCampaign';

const handlers = {
  'crashdown-client.pages.view': ({ payload }) => {
    const activeCampaign = new ActiveCampaign();
    activeCampaign.trackVisit(
      payload.email,
      payload.activeCampaignSiteTrackingAccountID,
    );
  },
};

function getHandler(event) {
  return handlers[event] || (() => {});
}

export default function ({ event, payload }) {
  getHandler(event)({ event, payload });
}
