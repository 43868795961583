export function simpleFormat(str) {
  str = str.replace(/\r\n?/, '\n');
  str = $.trim(str);
  if (str.length > 0) {
    str = str.replace(/\n\n+/g, '</p><p>');
    str = str.replace(/\n/g, '<br />');
    str = `<p>${str}</p>`;
  }
  return str;
}

export function htmlEscape(str) {
  return $('<div/>').text(str).html();
}

export function queryStringParameter(name) {
  const queryString = (window.location.search || '').replace('?', '');
  const searchParams = queryString.split('&')
    .filter(pair => !!pair && ~pair.indexOf('='))
    .map(pair => pair.split('='))
  const found = searchParams.filter(pair => pair[0] === name)

  if (found[0]) return found[0][1];
  else return null;
}

export function encodeURIComponentWwwForm(str) {
  return encodeURIComponentWwwFormFixup(encodeURIComponent(str));
}

export function encodeURIComponentWwwFormFixup(str) {
  /* In application/x-www-form-urlencoded, spaces are `+` instead of `%20` */
  return str.replace(/%20/g, '+');
}
