import React from 'react';
import ReactModal from 'react-modal';
import { ModalCloseIcon } from 'igniter/Icons';

export class Modal extends React.Component {
  componentWillUpdate(nextProps) {
    const { isOpen } = this.props;

    if(!isOpen && nextProps.isOpen) {
      $("#modal").foundation('close');
    }
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      className,
      children,
    } = this.props;

    const openClass = isOpen ? 'is-open' : '';

    return(
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName={{
          base: 'reveal-overlay',
          afterOpen: openClass,
          beforeClose: "",
        }}
        className={{
          base: `reveal ${className}`,
          afterOpen: openClass,
          beforeClose: "",
        }}
        bodyOpenClassName="is-reveal-open"
        ariaHideApp={false}
      >
        <div className="modal-content">
          {children}
        </div>

        <button className="close-button" aria-label="Close modal" type="button" onClick={onRequestClose}>
          <ModalCloseIcon />
        </button>
      </ReactModal>
    );
  }
}
