import React from 'react';

export class HomepageSearchUI extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearchInputKeyUp = this.handleSearchInputKeyUp.bind(this);
  }

  handleSearchInputKeyUp(e) {
    switch (e.key) {
      case 'Enter':
        this.props.executeSearch();
        break;
      default:
        break;
    }
  }

  render() {
    const {
      q,
      selectedProductType,
      siteProductTypes,
      onSearchInputChange,
      onProductTypeChange,
      executeSearch,
    } = this.props;

    return (
      <div className="input-group">
        <input
          type="text"
          id="carousel-search"
          className="input-group-field"
          placeholder="I'm searching for..."
          autoComplete="off"
          onChange={onSearchInputChange}
          onKeyUp={this.handleSearchInputKeyUp}
          value={q} />

        <span className="input-group-label">
          <select value={selectedProductType} onChange={onProductTypeChange}>
            <option key="0" value="all">All Products</option>
            {siteProductTypes.map((type, i) => <option key={i+1} value={type.id}>{type.name}</option>)}
          </select>
        </span>

        <div className="input-group-button">
          <input type="submit" className="button primary" value="&#xf002;" onClick={executeSearch} />
        </div>
      </div>
    );
  }
}
