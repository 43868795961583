import dataLayer from 'dataLayer';

const handlers = {
  'crashdown-client.pages.view': ({ payload }) => {
    const { plan_name } = payload;
    dataLayer.push({
      'event': 'page_view',
    });
  },
  'crashdown-client.content.view': ({ payload }) => {
    const { contents: [item] } = payload;
    dataLayer.push({
      'event': 'view_item',
      'currency': 'USD',
      'value': item.item_price,
      'items': [mapAlcItem(item)],
    });
  },
  'crashdown-client.content.search': ({ payload }) => {
    const { query: search_term, total_hits } = payload;
    dataLayer.push({
      'event': 'search',
      'search_term': search_term,
      'total_hits': total_hits,
    });
  },
  'crashdown-client.users.login': ({ payload }) => {
    const { method } = payload;
    dataLayer.push({
      'event': 'login',
      'method': method,
    });
  },
  'crashdown-client.cart.add-item': ({ payload }) => {
    const { product_name: name, currency, contents: [item] } = payload;

    let mappedItem = mapAlcItem({ name, ...item });

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
      'event': 'add_to_cart',
      'ecommerce': {
        'currency': currency,
        'value': mappedItem.price,
        'items': [mappedItem],
      }
    });
  },
  'crashdown-client.cart.remove-item': ({ payload }) => {
    const currency = 'USD';
    let mappedItem = mapAlcItem(payload);

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
      'event': 'remove_from_cart',
      'ecommerce': {
        'currency': currency,
        'value': mappedItem.price,
        'items': [mappedItem],
      }
    });
  },
  'crashdown-client.cart.view': ({ payload }) => {
    const { value, items } = payload;
    const currency = 'USD';

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
      'event': 'view_cart',
      'ecommerce': {
        'currency': currency,
        'value': value,
        'items': items.map(cartItemOrOrderMapper),
      },
    });
  },

  'crashdown.checkouts.new': ({ payload }) => {
    const { total, currency, cart_items: cartItems } = payload;

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
      'event': 'begin_checkout',
      'ecommerce': {
        'currency': currency,
        'value': total,
        'items': cartItems.map(cartItemOrOrderMapper),
      },
    });
  },
  'crashdown.checkouts.create': ({ payload }) => {
    const { invoice_id: invoiceId, currency, total, tax, shipping, orders } = payload;
    const affiliation = 'ig7';

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'transaction_id': invoiceId.toString(),
        'affiliation': affiliation,
        'value': parseFloat(total),
        'tax': parseFloat(tax),
        'shipping': parseFloat(shipping),
        'currency': currency,
        'items': orders.map(cartItemOrOrderMapper),
      },
    });
  },
};

function cartItemOrOrderMapper(cartItemOrOrder) {
  const { membership } = cartItemOrOrder;
  return (membership ? mapMembershipItem(cartItemOrOrder) : mapAlcItem(cartItemOrOrder) );
}

function mapMembershipItem(cartItem) {
  const {
    brand,
    id,
    membership_payment_period: membershipPaymentPeriod,
    membership_trial: membershipTrial,
    price,
    producer,
    quantity,
  } = cartItem;

  return {
    'item_brand': brand || producer,
    'item_id': membershipTrial ? 'MEMBERSHIP_TRIAL' : 'MEMBERSHIP',
    'item_name': id,
    'price': parseFloat(price),
    'quantity': quantity,
    'item_variant': membershipPaymentPeriod,
  }
}

function mapAlcItem(cartItem) {
  const {
    brand,
    producer,
    site_product_type: siteProductType,
    site_product_subtype: siteProductSubtype,
    id,
    item_price: itemPrice,
    name,
    price,
    quantity,
  } = cartItem;

  let item = {
    'item_brand': brand || producer,
    'item_category': siteProductType,
    'item_category2': siteProductSubtype,
    'item_id': id,
    'item_name': name,
    'item_variant': undefined,
    'price': parseFloat(price || itemPrice),
  };

  if (quantity !== undefined) item['quantity'] = quantity;

  return item;
}

function getHandler(event) {
  return handlers[event] || (() => {});
}

export default function ({ event, payload }) {
  getHandler(event)({ event, payload });
}
