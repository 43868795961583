import gtag from 'gtag'

const priceStringFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
function priceString(price) {
  return priceStringFormatter.format(price)
}

function eventAction(payload) {
  if (payload.membership_plan_name) {
    return 'checkout#create'
  }
  if (payload.has_design_credits) {
    return 'credits_checkout#create'
  }
  return 'cart_purchases#create'
}

function eventLabel(payload) {
  if (payload.membership_plan_name) {
    return payload.membership_plan_name
  }
  if (payload.has_design_credits) {
    return 'Credit Bundle'
  }
  return 'Cart Purchase'
}

function invoiceOrderMap(order) {
  return {
    id: order.id,
    name: order.name,
    brand: order.producer,
    category: order.description,
    price: priceString(order.price),
    quantity: order.quantity.toString(),
  }
}

function isFreeTrialStarted({ payload }) {
  return (
    payload.type === 'Membership' &&
    parseFloat(payload.subtotal) === 0
  )
}

function purchaseEventParams({ payload }) {
  return {
    transaction_id: payload.invoice_id.toString(),
    affiliation: 'ig7',
    value: priceString(payload.total),
    currency: 'USD',
    tax: priceString(payload.tax),
    shipping: priceString(payload.shipping),
    items: payload.orders.map(invoiceOrderMap),
  }
}

function isMembershipPurchase({ payload }) {
  return (
    payload.type === 'Membership' &&
    parseFloat(payload.subtotal) > 0
  )
}

function fireFreeTrialStarted() {
  gtag('event', 'free_trial_started', {
    currency: 'USD',
    value: priceString(200),
  })
}

function fireMembershipPurchase({ payload }) {
  gtag('event', 'membership_purchase', purchaseEventParams({ payload }))
}

function handler({ payload }) {
  if (isFreeTrialStarted({ payload })) {
    fireFreeTrialStarted({ payload })
  } else if (isMembershipPurchase({ payload })) {
    fireMembershipPurchase({ payload })
  }

  gtag('event', 'crashdown_event', {
    event_category: payload.type,
    event_action: eventAction(payload),
    event_label: eventLabel(payload),
    value: priceString(payload.subtotal),
  })

  gtag('event', 'purchase', purchaseEventParams({ payload }))
}

export default handler
