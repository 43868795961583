import events from './events';

export default {
  debug: false,

  track(event, payload = {}) {
    const handlers = events[event] || [];

    if (this.debug) {
      console.info(event, payload);

      if (handlers.length === 0) {
        console.warn(`${event} has no registered handlers!`);
      }
    }

    handlers.forEach((handler) => {
      handler({ event, payload });
    });
  },
};
