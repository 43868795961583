import '../../polyfills';
import document from 'document';
import window from 'window';
import WebpackerReact from 'webpacker-react';
import Analytics from '../../igniter/analytics';
import { FlashMessages } from '../../FlashMessages';
import { NavbarSearchWidget } from '../../igniter/SearchWidget';
import { TrialActivationButton } from '../../TrialActivationButton';
import { TrialDownloadsRemaining } from '../../TrialDownloadsRemaining';

window.Analytics = Analytics;

WebpackerReact.setup({
  FlashMessages,
  NavbarSearchWidget,
  TrialActivationButton,
  TrialDownloadsRemaining,
});

// CustomEvent polyfill
(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, options) {
    const params = options || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;

  return true;
}());

// Intl polyfill
(function () {
  if (window.Intl) return false;

  intl.__addLocaleData();
  window.Intl = intl;

  return true;
}());
