import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Alert } from './Alert';

export class FlashMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: props.messages };

    window.flashMessages = this;
  }

  addMessage(message) {
    const messageIndex = this.state.messages.findIndex(function(existingMessage) {
      return JSON.stringify(existingMessage) === JSON.stringify(this);
    }, message);
    let updatedMessages = null;

    if (messageIndex === -1) {
      updatedMessages = update(this.state.messages, { $push: [message] });
      this.setState({ messages: updatedMessages });
    }
  }

  removeMessage(message) {
    const index = this.state.messages.indexOf(message);
    const messages = update(this.state.messages, { $splice: [[index, 1]] });
    this.setState({ messages: messages });
  }

  get alerts() {
    const { messages } = this.state;

    return(
      messages.map((message) => {
        const { id, type, text, timeout } = message;

        return (
          <CSSTransition
            key={id}
            timeout={300}
            classNames='alerts'
          >
            <Alert
              type={type}
              text={text}
              timeout={timeout}
              onClose={() => this.removeMessage(message)}
            />
          </CSSTransition>
        );
      })
    );
  }

  render () {
    return(
      <TransitionGroup className="flash-messages">
        {this.alerts}
      </TransitionGroup>
    );
  }
}

FlashMessages.propTypes = {
  messages: PropTypes.array.isRequired
};
