var React = require('react');

function TimesCircleIcon (props) {
    return React.createElement("svg",props,[React.createElement("defs",{"key":0},React.createElement("style",null,"\n      .secondary-fill {\n        fill: none;\n        stroke: #fff;\n        stroke-width: 1.5px;\n      }\n    ")),React.createElement("g",{"transform":"translate(-12843 -1663)","key":1},[React.createElement("circle",{"className":"primary-fill","cx":"17.5","cy":"17.5","r":"17.5","transform":"translate(907.992 2493)","key":0}),React.createElement("line",{"className":"secondary-fill","x2":"12","y2":"12","transform":"translate(919.5 2504.5)","key":1}),React.createElement("line",{"className":"secondary-fill","y1":"12","x2":"12","transform":"translate(919.5 2504.5)","key":2})])]);
}

TimesCircleIcon.defaultProps = {"viewBox":"-11935.008 830 35 35","className":"icon times-circle"};

module.exports = TimesCircleIcon;

TimesCircleIcon.default = TimesCircleIcon;
