import gtm from './adapters/gtm';
import google from './adapters/google';
import activeCampaign from './adapters/activeCampaign';

export default {
  'crashdown-client.pages.view': [
    google,
    gtm,
    activeCampaign,
  ],
  'crashdown-client.content.view': [
    gtm,
  ],
  'crashdown-client.content.search': [
    gtm,
  ],
  'crashdown-client.product-browse.filter-interaction': [
    google,
  ],
  'crashdown-client.product-browse.toggle-filter-sidebar': [
    google,
  ],
  'crashdown-client.cart.add-item': [
    gtm,
  ],
  'crashdown-client.cart.remove-item': [
    gtm,
  ],
  'crashdown-client.cart.view': [
    gtm,
  ],
  'crashdown-client.users.login': [
    gtm,
  ],

  'crashdown.checkouts.create': [
    gtm,
    google,
  ],
  'crashdown.checkouts.new': [
    gtm,
  ],
  'crashdown.users.create': [
  ],
};
