import * as React from 'react'
import { Button, Callout, Cell, Grid } from 'react-foundation'
import { Modal } from '../../Modal'

declare var Routes: any

interface IProps {
  planName: string
  planPrice: number
  installmentCount: number
  isOpen: boolean
  handleClose: () => void
}

interface IState {
  errors: boolean
  activationStarted: boolean
}

export class TrialActivationConfirmationModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      errors: false,
      activationStarted: false,
    }
  }

  public activationErrorAlert() {
    const { errors } = this.state

    if (errors) {
      return (
        <Callout className="flash fade in error">
          Oops! There was a problem activating<br />
          your membership.<br />
          <a href="https://www.ignitermedia.com/contact">Contact Us</a>, or give us a call at 866-340-1826.
        </Callout>
      )
    }
  }

  public activateFullMembership = () => {
    this.setState({ activationStarted: true })
    $.post(Routes.igniter_create_trial_activation_path())
      .done(() => {
        location.reload()
      })
      .fail((xhr) => {
        const data = JSON.parse(xhr.responseText)
        this.setState({ errors: data.errors })
      })
  }

  public render() {
    const {
      activationStarted,
    } = this.state
    const {
      planName,
      planPrice,
      installmentCount,
      isOpen,
      handleClose,
    } = this.props

    let billingDescription = 'for'
    let moreInstallmentText = ''
    if (installmentCount > 1) {
      billingDescription = 'your first monthly installment of'
      moreInstallmentText = `
        Your remaining 11 installments will
        be charged each month automatically.
      `
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        className="trial-activation-confirmation-modal"
      >
        <Grid className="grid-padding-x text-center align-center">
          <Cell>
            <h3>Activate Paid Membership</h3>
          </Cell>

          <Cell>
            {this.activationErrorAlert()}

            <p>
              We're all set to start your {planName} Membership!
              We'll go ahead and charge your card {billingDescription} ${planPrice} plus
              applicable tax, and you can get started using your full
              membership right away. {moreInstallmentText}
            </p>
          </Cell>

          <Cell>
            <Button
              onClick={this.activateFullMembership}
              className="primary pill"
              isDisabled={activationStarted}
            >
              Start My {planName} Membership
            </Button>
          </Cell>
          <Cell>
            <a href={Routes.igniter_memberships_funnel_path()}>
              Explore Other Membership Options
            </a>
          </Cell>
        </Grid>
      </Modal>
    )
  }
}
