import React from 'react';
import { encodeURIComponentWwwFormFixup } from 'Utils';

export default function createSearchWidget(WidgetUI) {
  class SearchWidget extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        q: '',
        originalQ: null,
        selectedProductType: 'all',
        suggestions: [],
        selectedAutocompleteSuggestionIndex: -1,
      };

      this.onWidgetReset = this.onWidgetReset.bind(this);
      this.onExecuteSearch = this.onExecuteSearch.bind(this);
      this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
      this.handleProductTypeChange = this.handleProductTypeChange.bind(this);
      this.handleAutocompleteSelectionChange = this.handleAutocompleteSelectionChange.bind(this);

      this.getSuggestions = this.getSuggestions.bind(this);
      this.getSuggestionValue = this.getSuggestionValue.bind(this);
      this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
      this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    }

    handleSearchInputChange(e) {
      this.setState({
        q: e.target.value,
        originalQ: null,
        selectedAutocompleteSuggestionIndex: -1,
      });
      this.onSuggestionsFetchRequested(e.target);
    }

    handleProductTypeChange(e) {
      this.setState({ selectedProductType: e.target.value });
    }

    handleAutocompleteSelectionChange(by) {
      this.setState((prevState, props) => {
        const prevSelection = prevState.selectedAutocompleteSuggestionIndex;
        // compute the new autocomplete suggestion index
        let newSelection = prevSelection + by;

        // constrain it to the valid bounds of suggestions
        // remember: -1 is special (no autocomplete suggestion selected)
        if (newSelection < -1)
          newSelection = -1;
        if (newSelection >= prevState.suggestions.length)
          newSelection = prevState.suggestions.length - 1;

        let stateMods = {
          selectedAutocompleteSuggestionIndex: newSelection,
        };

        if (newSelection !== -1) {
          // if we're selecting a suggestion, let's put the suggestion
          // in the search input field
          if (prevState.originalQ === null)
            // but first, save what the search input as it was originally input by the user's keyboard
            stateMods.originalQ = prevState.q;
          stateMods.q = prevState.suggestions[newSelection].text;
        } else if (newSelection == -1) {
          // so you can restore it when "arrowing" up out of the suggestion list
          stateMods.q = prevState.originalQ;
        }

        return stateMods;
      });
    }

    onWidgetReset() {
      this.setState((prevState, props) => {
        return {
          q: '',
          selectedProductType: 'all',
          suggestions: [],
        };
      });
    }

    onExecuteSearch() {
      const {
        q,
        selectedProductType,
        suggestions,
        selectedAutocompleteSuggestionIndex,
      } = this.state;

      if (selectedAutocompleteSuggestionIndex != -1) {
        window.location.href = suggestions[selectedAutocompleteSuggestionIndex].url;
      } else {
        let url = null;
        if (selectedProductType == 'all')
          url = this._buildSearchUrl(q);
        else
          url = this._buildBrowseUrl(selectedProductType, q);

        window.location.href = encodeURIComponentWwwFormFixup(url);
      }
    }

    _buildSearchUrl(keywordQuery) {
      return Routes.igniter_search_path({ q: keywordQuery });
    }

    _buildBrowseUrl(productTypeId, keywordQuery) {
      const { siteProductTypes } = this.props;
      const productType = siteProductTypes.find(pt => pt.id == productTypeId);
      return `/${productType.uri}?q=${encodeURIComponent(keywordQuery)}`;
    }

    get suggestions() {
      const xlate = link => ({
        text: link.title,
        url: link.url,
      });

      let combined = this.props.autocomplete.quicklinks.map(xlate);
      combined.push(...this.props.autocomplete.producers.map(xlate));

      return combined;
    }

    getSuggestions(value) {
      if (value === null)
        return [];

      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 ? [] : this.suggestions.filter(s =>
        s.text.toLowerCase().slice(0, inputLength) === inputValue
      );
    }

    getSuggestionValue(suggestion) {
      return suggestion.text;
    }

    onSuggestionsFetchRequested({ value }) {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    }

    onSuggestionsClearRequested() {
      this.setState({
        suggestions: []
      });
    }

   render() {

      return (<WidgetUI
        q={this.state.q}
        suggestions={this.state.suggestions}
        selectedAutocompleteSuggestion={this.state.selectedAutocompleteSuggestionIndex}
        handleAutocompleteSelectionChange={this.handleAutocompleteSelectionChange}
        selectedProductType={this.state.selectedProductType}
        onSearchInputChange={this.handleSearchInputChange}
        onProductTypeChange={this.handleProductTypeChange}
        resetSearchWidget={this.onWidgetReset}
        executeSearch={this.onExecuteSearch}
        {...this.props}
      />);
    }
  }

  SearchWidget.displayName = `SearchWidget(${getDisplayName(WidgetUI)})`;
  return SearchWidget;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
