import React from 'react';
import PropTypes from 'prop-types';
import { Callout, CloseButton } from 'react-foundation';
import { TimesCircleIcon } from 'igniter/Icons';

export class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoCloseTimer: null,
    };
  }

  componentDidMount() {
    const { onClose } = this.props;

    if (this.alert.timeout != null) {
      const timer = setTimeout(
        onClose,
        this.alert.timeout
      );

      this.setState({
        autoCloseTimer: timer,
      });
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;

    clearTimeout(timer);
  }

  get alert() {
    const { text, type, timeout } = this.props;

    const alertTypes = {
      error: {
        className: 'error',
        text: text,
        timeout: timeout,
      },
      alert: {
        className: 'warn',
        text: text,
        timeout: timeout,
      },
      info: {
        className: 'info',
        text: text,
        timeout: timeout || 10000,
      },
      notice: {
        className: 'success',
        text: text,
        timeout: timeout || 5000,
      },
    };

    return (alertTypes[type] || alertTypes.notice);
  }

  render() {
    const { onClose } = this.props;
    const alertClassName = `flash ${this.alert.className} fade in`;

    return(
      <Callout className={alertClassName}>
        <CloseButton onClick={onClose}><TimesCircleIcon /></CloseButton>
        {this.alert.text}
      </Callout>
    );
  }
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  timeout: PropTypes.number,
};
