import AwesomeIcon from '!svg-react-loader?name=AwesomeIcon!images/igniter/svgs/awesome.svg';
import BookmarkIcon from '!svg-react-loader?name=BookmarkIcon!images/igniter/svgs/bookmark.svg';
import CalculatorIcon from '!svg-react-loader?name=CalculatorIcon!images/igniter/svgs/calculator.svg';
import CheckCircleIcon from '!svg-react-loader?name=CheckCircleIcon!images/igniter/svgs/check-circle.svg';
import CheckIcon from '!svg-react-loader?name=CheckIcon!images/igniter/svgs/check.svg';
import CheckIcon2024 from '!svg-react-loader?name=CheckIcon2024!images/igniter/svgs/check-2024.svg';
import CheckSkinnyIcon from '!svg-react-loader?name=CheckIcon!images/igniter/svgs/check-skinny.svg';
import ChurchBannersLogo from '!svg-react-loader?name=ChurchBannersLogo!images/igniter/svgs/church-banners.svg';
import CogIcon from '!svg-react-loader?name=CogIcon!images/igniter/svgs/cog.svg';
import CreditCardIcon from '!svg-react-loader?name=CreditCardIcon!images/igniter/svgs/credit-card.svg';
import CustomerSupportIcon from '!svg-react-loader?name=CustomerSupportIcon!images/igniter/svgs/customer-support.svg';
import DollarIcon from '!svg-react-loader?name=DollarIcon!images/igniter/svgs/dollar.svg';
import DownloadIcon from '!svg-react-loader?name=DownloadIcon!images/igniter/svgs/download.svg';
import DropboxIcon from '!svg-react-loader?name=DownloadIcon!images/igniter/svgs/dropbox-logo.svg';
import EmailIcon from '!svg-react-loader?name=EmailIcon!images/igniter/svgs/email.svg';
import EmbedIcon from '!svg-react-loader?name=EmbedIcon!images/igniter/svgs/embed.svg';
import ExclusiveIcon from '!svg-react-loader?name=ExclusiveIcon!images/igniter/svgs/exclusive.svg';
import ExploreTheSiteIcon from '!svg-react-loader?name=ExploreIcon!images/igniter/svgs/explore-the-site.svg';
import ExploreYourMembershipIcon from '!svg-react-loader?name=MembershipExploreIcon!images/igniter/svgs/explore-your-membership.svg';
import FacebookLogoOld from '!svg-react-loader?name=FacebookLogo!images/igniter/svgs/facebook-old.svg';
import FacebookLogo from '!svg-react-loader?name=FacebookLogo!images/igniter/svgs/facebook.svg';
import FilterButtonIcon from '!svg-react-loader?name=FilterButtonIcon!images/igniter/svgs/filter-button.svg';
import FindAMembershipIcon from '!svg-react-loader?name=FindAMembershipIcon!images/igniter/svgs/find-a-membership.svg';
import GracewayLogo from '!svg-react-loader?name=GracewayLogo!images/igniter/svgs/graceway-logo.svg';
import HelpCenterIcon from '!svg-react-loader?name=HelpCenterIcon!images/igniter/svgs/help-center.svg';
import InfoCircleIcon from '!svg-react-loader?name=CheckCircleIcon!images/igniter/svgs/info-circle.svg';
import InstagramLogoOld from '!svg-react-loader?name=InstagramLogo!images/igniter/svgs/instagram-old.svg';
import InstagramLogo from '!svg-react-loader?name=InstagramLogo!images/igniter/svgs/instagram.svg';
import ManyProducersIcon from '!svg-react-loader?name=ManyProducersIcon!images/igniter/svgs/many-producers.svg';
import ModalCloseIcon from '!svg-react-loader?name=ModalCloseIcon!images/igniter/svgs/modal-close.svg';
import OncePerYearIcon from '!svg-react-loader?name=OncePerYearIcon!images/igniter/svgs/once-per-year.svg';
import PrinterIcon from '!svg-react-loader?name=PrinterIcon!images/igniter/svgs/printer.svg';
import QuestionCircleIcon from '!svg-react-loader?name=QuestionCircleIcon!images/igniter/svgs/question-circle.svg';
import QuotesIcon from '!svg-react-loader?name=QuestionCircleIcon!images/igniter/svgs/quotes.svg';
import RadioButtonIcon from '!svg-react-loader?name=RadioButtonIcon!images/igniter/svgs/radio-button.svg';
import RetitleIcon from '!svg-react-loader?name=RetitleIcon!images/igniter/svgs/retitle.svg';
import SearchIcon from '!svg-react-loader?name=SearchIcon!images/igniter/svgs/search.svg';
import SearchThinIcon from '!svg-react-loader?name=SearchThinIcon!images/igniter/svgs/search-thin.svg';
import SeeWhatsNewIcon from '!svg-react-loader?name=SeeWhatsNewIcon!images/igniter/svgs/see-whats-new.svg';
import SignInIcon from '!svg-react-loader?name=SignInIcon!images/igniter/svgs/signin.svg';
import TexasIcon from '!svg-react-loader?name=TexasIcon!images/igniter/svgs/texas.svg';
import TimesCircleIcon from '!svg-react-loader?name=TimesCircleIcon!images/igniter/svgs/times-circle.svg';
import TrophyIcon from '!svg-react-loader?name=TrophyIcon!images/igniter/svgs/trophy.svg';
import UnlockIcon from '!svg-react-loader?name=UnlockIcon!images/igniter/svgs/unlock.svg';
import UserIcon from '!svg-react-loader?name=UserIcon!images/igniter/svgs/user.svg';
import WebDemoIcon from '!svg-react-loader?name=WebDemoIcon!images/igniter/svgs/web-demo.svg';

export {
  AwesomeIcon,
  BookmarkIcon,
  CalculatorIcon,
  CheckCircleIcon,
  CheckIcon,
  CheckIcon2024,
  CheckSkinnyIcon,
  ChurchBannersLogo,
  CogIcon,
  CreditCardIcon,
  CustomerSupportIcon,
  DollarIcon,
  DownloadIcon,
  EmailIcon,
  EmbedIcon,
  ExclusiveIcon,
  ExploreTheSiteIcon,
  ExploreYourMembershipIcon,
  FacebookLogoOld,
  FacebookLogo,
  FilterButtonIcon,
  FindAMembershipIcon,
  GracewayLogo,
  HelpCenterIcon,
  InfoCircleIcon,
  InstagramLogoOld,
  InstagramLogo,
  ManyProducersIcon,
  ModalCloseIcon,
  OncePerYearIcon,
  PrinterIcon,
  QuestionCircleIcon,
  QuotesIcon,
  RadioButtonIcon,
  RetitleIcon,
  SearchIcon,
  SearchThinIcon,
  SeeWhatsNewIcon,
  SignInIcon,
  TexasIcon,
  TimesCircleIcon,
  TrophyIcon,
  UnlockIcon,
  UserIcon,
  WebDemoIcon,
};
