var React = require('react');

function FacebookLogo (props) {
    return React.createElement("svg",props,React.createElement("path",{"className":"primary-fill","d":"M18.9,0H1.1C0.5,0,0,0.5,0,1.1v17.8C0,19.5,0.5,20,1.1,20h9.6v-7.7H8.1v-3h2.6V7\n\tc0-2.6,1.6-4,3.9-4c1.1,0,2.1,0.1,2.3,0.1v2.7l-1.6,0c-1.3,0-1.5,0.6-1.5,1.5v1.9h3l-0.4,3h-2.6V20h5.1c0.6,0,1.1-0.5,1.1-1.1V1.1\n\tC20,0.5,19.5,0,18.9,0z"}));
}

FacebookLogo.defaultProps = {"id":"Layer_1","x":"0px","y":"0px","viewBox":"0 0 20 20","style":{"enableBackground":"new 0 0 20 20"},"xmlSpace":"preserve"};

module.exports = FacebookLogo;

FacebookLogo.default = FacebookLogo;
