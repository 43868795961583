var React = require('react');

function DownloadIcon (props) {
    return React.createElement("svg",props,[React.createElement("style",{"key":0},"\n\t\tsvg.download.icon .no-fill { fill: none; }\n\t\tsvg.download.icon .thick-stroke { stroke-width: 2px; }\n\t"),React.createElement("path",{"id":"Path_7497","className":"primary-fill","d":"M15.7,20.4H1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h14.4c0.7,0,1.3,0.6,1.3,1.3\n\t\tC17,19.8,16.4,20.4,15.7,20.4z","key":1}),React.createElement("path",{"className":"primary-fill","d":"M14.2,7.8h-3.6V2.1c0,0,0,0,0,0C10.6,0.9,9.7,0,8.5,0c-1.1,0.1-2,1-2.1,2.1v5.7H2.7C2,7.8,1.8,8.1,2.2,8.6\n\t\tl5.5,6.6c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.3,1,0.2,1.3-0.2l5.5-6.6C15,8.1,14.8,7.8,14.2,7.8z","key":2})]);
}

DownloadIcon.defaultProps = {"className":"download icon","x":"0px","y":"0px","viewBox":"0 0 17 20.4"};

module.exports = DownloadIcon;

DownloadIcon.default = DownloadIcon;
