export default class ActiveCampaign {
  trackVisit(email, actid) {
    const encodedEmail = encodeURIComponent(email || '');
    const referrer = encodeURIComponent(document.referrer);
    const url = encodeURIComponent(window.location.href);

    fetch(
      `https://trackcmp.net/visit?actid=${actid}&e=${encodedEmail}&r=${referrer}&u=${url}`,
      {
        mode: 'no-cors',
        credentials: 'same-origin',
      },
    );
  }
}
